.App {
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
}

.menu-icon,
.logo,
.search-icon {
  height: 40px;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  gap: 100px !important;
}

.banner-image {
  width: 50% !important;
  height: auto;
}

.delivery-section {
  text-align: left;
  padding: 20px;
}

.delivery-section h1 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.delivery-section p {
  margin-bottom: 20px;
}

.delivery-section button {
  background-color: #00aaff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.features {
  padding: 20px;
  text-align: center;
}

.feature {
  margin-bottom: 20px;
}

.feature img {
  width: 50px;
  height: 50px;
}

.footer {
  padding: 20px;
  background-color: #ffffff;
  color: #3a3a3a;
  text-align: center;
}

.footer-logo {
  height: 40px;
  margin-bottom: 20px;
}

.download-links img {
  height: 40px;
  margin: 0 10px;
}

.special-categories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.special-category {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.special-category img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.special-category p {
  font-size: 0.9em;
}

/* Mobil Uyumlu Hale Getirmek İçin Media Queries */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .banner-content {
    flex-direction: column;
    width: 100%;
    gap: 20px !important;
  }

  .banner-image {
    width: 100% !important;
  }

  .delivery-section {
    padding: 10px;
  }

  .features {
    padding: 10px;
  }

  .footer {
    padding: 10px;
  }

  .special-categories {
    grid-template-columns: 1fr;
  }

  .special-category {
    padding: 5px;
  }

  .special-category img {
    width: 40px;
    height: 40px;
  }

  .special-category p {
    font-size: 0.8em;
  }
}

@media (max-width: 480px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .banner-content {
    flex-direction: column;
    width: 100%;
    gap: 10px !important;
  }

  .banner-image {
    width: 100% !important;
  }

  .delivery-section {
    padding: 5px;
  }

  .features {
    padding: 5px;
  }

  .footer {
    padding: 5px;
  }

  .special-categories {
    grid-template-columns: 1fr;
  }

  .special-category {
    padding: 5px;
  }

  .special-category img {
    width: 30px;
    height: 30px;
  }

  .special-category p {
    font-size: 0.7em;
  }
}
