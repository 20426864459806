body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: #f8f8f8;
}

.chance {
  background-color: #f8f8f8;
  padding: 20px;
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

.chance h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.chance-container {
  display: flex;
  gap: 40px;
  justify-content: center;
}

.chance-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chance-image {
  width: 150px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.chance-info {
  text-align: left;
  margin-bottom: 10px;
}

.chance-old-price {
  text-decoration: line-through;
  color: #888;
}

.chance-new-price {
  color: #f00;
  font-weight: bold;
}

.chance-name {
  font-size: 0.9em;
  margin-bottom: 5px;
}

.chance-points {
  background-color: #00aaff;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.chance-button {
  background-color: #00aaff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.view-all-button {
  background-color: #00aaff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 20px;
}
