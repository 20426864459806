.features {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  text-align: center;
  flex-wrap: wrap;
}

.feature {
  flex: 1 1 21%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f8f8f8;
}

.feature img.feature-image {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.feature h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.feature p {
  font-size: 1em;
}

.feature:hover {
  transform: translateY(-5px);
}
