/* Genel Header Stili */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 10;
}

/* Header bölümleri */
.header-left,
.header-center,
.header-right {
  display: flex;
  align-items: center;
}

/* İkon ve Logo Stili */
.menu-icon,
.logo,
.search-icon,
.kampanya-icon,
.cart-icon {
  height: 40px;
  margin-right: 15px;
  cursor: pointer;
  transition: transform 0.2s;
}

.menu-icon:hover,
.logo:hover,
.search-icon:hover,
.kampanya-icon:hover,
.cart-icon:hover {
  transform: scale(1.1);
}

/* Arama Çubuğu Stili */
.search-input {
  border: 1px solid #ced4da;
  border-radius: 25px;
  padding: 8px 15px;
  width: 350px;
  transition: width 0.3s ease-in-out;
  margin-left: 10px;
}

.search-input:focus {
  width: 400px;
  border-color: #495057;
  outline: none;
}

/* Giriş Butonu Stili */
.login-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 25px;
  border-radius: 25px;
  cursor: pointer;
  margin-right: 15px;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #218838;
}

/* Sidebar Stili */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease;
  z-index: 1000;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

.sidebar.open {
  left: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.menu-item {
  padding: 15px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #f1f1f1;
}

/* Backdrop Stili */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Mobil Dostu Tasarım */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    padding: 10px 20px;
  }

  .search-input {
    width: 100%;
    margin-top: 10px;
  }

  .login-button {
    margin-top: 10px;
  }
}
