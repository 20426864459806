.contact-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .bilgiler-form-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .iletisim-bilgilerimiz, .kurumsal-iletisim-formu {
    flex: 1;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .iletisim-bilgilerimiz h2, .kurumsal-iletisim-formu h2 {
    color: #2c7d59;
  }
  
  .iletisim-bilgilerimiz p, .kurumsal-iletisim-formu p {
    margin: 10px 0;
    color: #333;
  }
  
  .map-container {
    margin-top: 20px;
  }
  
  .kurumsal-iletisim-formu form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin: 15px 0;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-group input, .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #2c7d59;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #245d47;
  }
  