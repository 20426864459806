body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
  }
  
  .container {
    display: flex;
    width: 100%;
  }
  
  /* Kategorilerin listelendiği kısım*/
  .sidebar1 {
    width: 25%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: sticky;
    top: 10px;
    height: calc(100vh - 20px);
    overflow-y: auto;
  }
  
  .sidebar1 h2 {
    font-size: 24px;
    color: #333;
    border-bottom: 2px solid #ff0000;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .sidebar1 form {
    display: flex;
    flex-direction: column;
  }
  
  .sidebar1 label {
    margin-bottom: 15px;
    font-size: 14px;
    color: #555;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .sidebar1 input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.2);
    cursor: pointer;
    accent-color: #ff0000;
  }
  
  .sidebar1 label b {
    color: #ff0000;
  }
  
  .sidebar1 hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }
  
  .sidebar1 label:hover {
    color: #ff0000;
    font-size: 15px;
    font-weight: bold;
  }
  
  .sidebar1 input[type="checkbox"]:hover {
    accent-color: #ff0000;
  }
  
  /* Kartların düzenlendiği kısım */
  
  .main-content {
    flex-grow: 1;
    padding: 10px;
  }
  
  #product-container {
    transition: opacity 0.5s ease-in-out;
  }
  
  .product-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    margin-top: 50px;
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.2s;
    width: calc(30% - 10px);
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-card img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .product-card h3 {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .product-card p {
    font-size: 0.9em;
    color: #666;
  }
  
  .product-card button {
    background-color: #ff4242;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s;
  }
  
  .product-card button:hover {
    background-color: #ff0000;
  }
  
  .product-card:hover {
    transform: scale(1.04);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .related-categories {
    margin-top: 20px;
  }
  
  .related-categories h3 {
    margin-bottom: 10px;
  }
  
  .related-categories label {
    display: block;
    margin-bottom: 5px;
  }
  
  /* Dropdown kısmı */
  
  .dropdown {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .dropdown-button {
    background-color: #ffffff;
    color: black;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    cursor: pointer;
    border-radius: 17px;
    transition: background-color 0.3s;
    padding: 13px;
  }
  
  .dropdown-button:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 17px;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s;
    border-radius: 17px;
  }
  
  .dropdown-content a:hover {
    background-color: #ffdede;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  