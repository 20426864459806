.about-container {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-container h2 {
  color: #2c7d59;
}

.about-container p {
  margin: 10px 0;
  color: #333;
}

.kurucu {
  display: flex;
  margin-top: 50px;
}

.kurucu img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-right: 40px;
}

.kurucu h3 {
  color: #2c7d59;
}

.kurucu p {
  margin: 20px 0;
}
