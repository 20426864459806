/* Dil Seçici Stili */
.language-selector {
  position: relative;
  display:flex;
  z-index: 10;
}

/* Dil Butonu Stili */
.language-button {
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

/* Dil Seçenekleri Stili */
.language-options {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;
}

.language-options button {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.language-options button:hover {
  background-color: #f0f0f0;
}

/* Dil Seçeneklerini Görünür Yap */
.language-selector:hover .language-options {
  display: flex;
}
