.delivery-section {
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 1200px;
}

.delivery-section h1 {
  font-size: 1.5em;
  margin: 0;
}

.delivery-section p {
  margin: 0;
  flex-grow: 1;
  padding-left: 20px;
}

.delivery-section button {
  background-color: #00aaff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-left: 20px;
}
