.footer {
  background-color: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.footer-top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.footer-logo {
  height: 40px;
  margin-bottom: 20px;
}

.footer-social {
  display: flex;
  gap: 10px;
}

.footer-social img {
  width: 56px;
  height: 56px;
}

.footer-qr {
  text-align: center;
}

.footer-qr img {
  width: 100px;
  height: 100px;
}

.footer-middle {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}

.footer-column {
  text-align: left;
}

.footer-column h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #3d3d3d;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #444444;
  color: #fff;
}

.footer-payment img {
  height: 40px;
  margin: 0 10px;
}

.footer-copyright {
  flex-grow: 1;
  text-align: center;
}

.footer-cards img {
  height: 40px;
  margin: 0 10px;
}
