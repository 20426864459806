.categories {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  margin: 120px;
}

.category {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.category:hover {
  transform: translateY(-5px);
}

.category img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.category p {
  font-size: 1em;
  margin: 0;
}

/* Mobil Dostu Tasarım */
@media (max-width: 768px) {
  .categories {
    grid-template-columns: repeat(2, 1fr);
  }
}
